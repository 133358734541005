/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BroadbandSubscriptionDetails } from '../models/BroadbandSubscriptionDetails';
import type { SubscriptionOverview } from '../models/SubscriptionOverview';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionControllerService {

    /**
     * @returns SubscriptionOverview OK
     * @throws ApiError
     */
    public static getSubscriptions(): CancelablePromise<Array<SubscriptionOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1',
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

    /**
     * @param category
     * @param id
     * @returns BroadbandSubscriptionDetails OK
     * @throws ApiError
     */
    public static getSubscriptionDetails(
        category: string,
        id: string,
    ): CancelablePromise<BroadbandSubscriptionDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/{category}/{id}',
            path: {
                'category': category,
                'id': id,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
                503: `Service Unavailable`,
            },
        });
    }

}